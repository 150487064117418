import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faListAlt, faSmile } from "@fortawesome/free-regular-svg-icons"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureIcon icon={faListAlt} size="4x" />
          <div>
            <FeatureTitle>
              Als Welpen-Neubesitzer müssen Sie sich plötzlich um viele Dinge
              kümmern
            </FeatureTitle>
            <FeatureText>
              Mit einem neuen Welpen haben Sie alle Hände voll zu tun. Sie
              müssen Ihren Haushalt welpensicher machen, eine Erstausstattung
              kaufen, sich um die Gesundheitsvorsorge kümmern, Ihren Hund
              anmelden und versichern sowie vieles mehr. Hierbei kann man
              schnell den Überblick verlieren, etwas Wichtiges vergessen oder
              etwas falsch machen.
            </FeatureText>
          </div>
        </FeatureItem>
        <FeatureItem>
          <div>
            <FeatureTitle>
              Perfekt vorbereitet mit dem WelpenOrganizer
            </FeatureTitle>
            <FeatureText>
              Sparen Sie Zeit, Geld und Nerven. Dank dem WelpenOrganizer haben
              Sie alles unter Kontrolle, sind bestens vorbereitet und können
              sich ganz um Ihren neuen Liebling kümmern.
            </FeatureText>
          </div>
          <FeatureIcon icon={faSmile} size="4x" />
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0px auto;
  grid-row-gap: 50px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`
const FeatureIcon = styled(FontAwesomeIcon)`
  margin: 50px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 20px;
    font-size: 32px;
  }
`

const FeatureText = styled.p`
  /* text-align: center; */
`
