import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import FeatureHeader from "../components/sections/feature-header"
import Features from "../components/sections/features"
import FeatureLinks from "../components/sections/feature-links"
import Footer from "../components/sections/footer"

const headline = "Stressfrei durch die Welpenzeit"
const text =
  " Mit dem WelpenOrganizer behalten Sie jederzeit den Überblick über alle notwendigen Aufgaben"

const IndexPage = () => (
  <Layout>
    <SEO title="Alle Aufgaben für den Welpen im Blick" />
    <Navigation />
    <FeatureHeader text={text} headline={headline} />
    <Features />
    <FeatureLinks />
    <Footer />
  </Layout>
)

export default IndexPage
